.App {
    width: 100%;
    height: 100%;
    font-family: 'Montserrat', -apple-system, sans-serif;
    display: flex;
    flex-direction: row;
}

.Sidebar{
    width: 70px;
    height: 100%;
    z-index: 2;
}

.Content{
    flex: 1;
    top: 0;
    left: 40px;
    background-color: #212121;
    color: #CACACA;
    display: flex;
}

@media screen and (max-width: 680px) {
    .App {
        flex-direction: column-reverse;
    }

    .Sidebar{
        width: 100%;
        height: 60px;
    }

    .Content{
        overflow-y: scroll;
    }
}




