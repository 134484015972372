.Contact-me-container {
    width: 100%;
    text-align: center;
}

.Title {
    text-align: center;
    font-size: 32px;
}

.Center-content {
    position: absolute;
    top: 0;
    left: 70px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Social-container {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    align-self: center;
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.My-logo {
    position: absolute;
    right: 50px;
    top: 40%;
    opacity: 0.6;
    transform: rotate(25deg);
    width: 180px;
    height: 180px;
}

@media screen and (max-width: 680px) {
    .Title {
        font-size: 20px;
    }

    .Contact-me-content{
        text-align: center;
        font-size: 13px;
    }

    .Social-container{
        justify-content: space-between;
        margin-right: 0;
        margin-left: 0;
        width: 70%;
    }

    .Center-content{
        left: 0;
    }

    .Contact-me-content-bottom{
        position: absolute;
        bottom: 10px;
        text-align: center;
        font-size: 13px;
    }

    .My-logo{
        visibility: visible;
        top: 60%;
        width: 100px;
        height: 100px;
    }
}

