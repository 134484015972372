.Header {
    text-align: center;
    font-size: 32px;
}

.Sub-title {
    text-align: center;
    margin-left: 25%;
    margin-right: 25%;
    margin-top: 150px;
    white-space: pre-line;
}


.List-container {
    margin-top: 100px;
}


/* Mouse Styling */
.mouse {
    margin-top: 70px;
    display: block;
    width: 28px;
    height: 48px;
    border-radius: 11px 11px 15px 15px;
    border: 1px solid #CACACA;
    margin-left: auto;
    margin-right: auto;
    z-index: 20;
}

.mouse span {
    display: block;
    margin: 6px auto;
    width: 2px;
    height: 5px;
    border-radius: 4px;
    background: #fff;
    border: 1px solid transparent;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: scroll;
    animation-name: scroll;
}

@keyframes scroll {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        -ms-transform: translateY(20px);
        transform: translateY(20px);
    }
}

@media screen and (max-width: 680px){
    .Header {
        font-size: 20px;
    }

    .Sub-title {
        text-align: center;
        font-size: 12px;
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 10px;
        white-space: pre-line;
    }


}
