.Container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    overflow-y: scroll;
}

.profile-title-container{
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.profile-picture{
    width: 100px;
    height: 100px;
    margin: auto auto 8px;
}

.About-container{
    display: inline-block;
    text-align: left;
    white-space: pre-line;
    width: 50%;
    margin-bottom: 70px;
    font-size: 14px;
}

.about-list-container{
    visibility: hidden;
}

.Title{
    text-align: center;
    font-size: 32px;
}

.My-logo-about-me{
    position: absolute;
    right: 50px;
    top: 40%;
    opacity: 0.6;
    transform: rotate(25deg);
    width: 180px;
    height: 180px;
}

@media screen and (max-width: 680px) {

    .Container{
        flex-direction: row;
    }

    .My-logo-about-me{
        visibility: hidden;
    }

    .Title{
        font-size: 20px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .About-container{
        display: none;
    }
    .about-list-container{
        visibility: visible;
    }

    .Container{
        padding-bottom: 300px;
        overflow: scroll;
    }

    .My-logo{
        visibility: hidden;
    }

}

@media screen and (max-height: 650px){
    .Container{
        padding-bottom: 20px;
        overflow: scroll;
    }
}
