.Home {
    width: 100%;
    height: 100%;
}

.Content-container {
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
}


.Name-header {
    text-align: center;
    font-size: 45px;
    margin: 20px;
}


.Sub-title-one {
    text-align: center;
    font-size: 25px;
    margin: 10px;
    height: 30px;
    color: white;

}

.Sub-title-two {
    text-align: center;
    font-size: 20px;
}

.Particles {
    position: fixed;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.My-work-link {
    display: inline-block;
    text-align: center;
    color: #CACACA;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.Right-arrow {
    width: 20px;
    height: 20px;
    margin-top: 5px;
}

.Link-container {
    text-align: center;
}

.Link-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


@media screen and (max-width: 680px) {
    .Content-container {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .Name-header {
        font-size: 30px;
    }

    .Sub-title-one {
        font-size: 18px;
        margin: 10px;
    }

    .Sub-title-two {
        font-size: 14px;
    }

    .Link-content {
        font-size: 12px;
    }
}
