
.Separator {
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, rgba(255, 205, 0, 0.5), rgba(0, 0, 0, 0));
}

.image-text-container{
    display: flex;
    flex-direction: row;
}

.List-image {
    height: 500px;
    margin-left: 50px;
    margin-top: 20px;
    margin-bottom: 50px;
    max-width: 500px;
    object-fit: scale-down;
}

.List-text {
    text-align: left;
    margin: auto 150px auto 30px;
}


.item-container :hover{
    background: #313131
}

@media screen and (max-width: 680px){

    .image-text-container{
        flex-direction: column;
    }

    .List-text{
        text-align: center;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 20px;
        font-size: 14px;
    }

    .List-image{
        width: 250px;
        height: 250px;
        margin: auto;
    }

}

