.Project {
    display: flex;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    flex-direction: column;
    align-items: center;
}

.title-image-container{
    display: flex;
    flex-direction: row;
}

.carousel-container{
    max-height: 700px;
    width: 700px;
    margin-top: 32px;
    min-width: 355px;
    display: block;
}

.Full-Logo {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 30px;
}

.Image {
    max-width: 100%;
}

.Dot {
    color: #CACACA;
}

.Description-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    margin-top: 60px;
    width: 45%;
    padding-bottom: 50px;
}

.Full-description {
    text-align: left;
    white-space: pre-line;
}

.Links-container{
    display: flex;
    width: 100%;
    margin-bottom: 30px;
}

.Link{
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    color: #CACACA;
}

@media screen and (max-width: 680px){
    .Description-container {
        width: 90%;
    }

    .Full-description{
        font-size: 13px;
    }

    .carousel-container{
        max-width: 90%;
    }
}
